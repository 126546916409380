import { ErrorOutline } from "@mui/icons-material";
function DataNotFound({ message }) {
  return (
    <div className="text-red-900 flex items-center justify-center gap-2 font-light gap-4 text-3xl">
      <ErrorOutline sx={{ fontSize: "50px" }} />
      {message}
    </div>
  );
}

export default DataNotFound;
