const ImageData = ({ imageData, usedImageStats }) => {
  return (
    imageData &&
    Object.keys(imageData).length > 1 && (
      <ul className=" min-w-full  break-words ">
        {Object.keys(imageData)
          .filter((meta) => {
            return usedImageStats.includes(meta);
          })
          .map((meta) => {
            return (
              <li className="py-2">
                <p className="font-extrabold text-lg text-red-800  ">
                  {meta.toUpperCase()}
                </p>
                {meta === "confidence" ? (
                  <span className="font-light ">
                    {parseFloat(Math.round(imageData[meta] * 10000)) / 100}%
                  </span>
                ) : meta === "ts" ? (
                  <span className="font-light  ">
                    {new Date(imageData[meta] + " GMT").toLocaleString(
                      "en-US",
                      { timeZone: "America/Chicago" }
                    )}
                  </span>
                ) : (
                  <span className="font-light ">{imageData[meta]}</span>
                )}
              </li>
            );
          })}
      </ul>
    )
  );
};

export default ImageData;
