import DataNotFound from "./DataNotFound";
import PrimaryButton from "./PrimaryButton";
import { useState, useEffect } from "react";
import SecondaryButton from "./SecondaryButton";
import { FadeLoader } from "react-spinners";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function HomeContent({
  locations,
  activeLocation,
  judgeButtons,
  judgeButtonState,
  setActiveLocation,
  imageData,
  imageNotFound,
  setImageNotFound,
  imageLoading,
  setImageLoading,
  fetchNextImage,
  intervalId,
  setIntervalId,
  imageHistory,
  setImageIndex,
  setJudgeButtonState,
}) {
  const [intervalSet, setIntervalSet] = useState(false);
  const [count, setCount] = useState(0);
  function clearJudgeButtonStats() {
    const judgeButtonStateCopy = { ...judgeButtonState };
    for (const judgeButton in judgeButtonStateCopy) {
      console.log(judgeButtonState[judgeButton]);
      judgeButtonStateCopy[judgeButton] = 0;
    }
    console.log(judgeButtonStateCopy);
    setJudgeButtonState(judgeButtonStateCopy);
  }
  useEffect(() => {
    if (activeLocation !== "") {
      clearJudgeButtonStats();
    }
  }, [activeLocation]);

  /*
  useEffect(() => {
    const checkIfNullResponse = async () => {
      if (imageHistory.length > 0) {
        //account for null responses
        if (Object.keys(imageHistory[imageHistory.length - 1]).length === 1) {
          setImageNotFound(true);
          await handleImageError();
        }
      }
    };
    checkIfNullResponse();
  }, [imageHistory]);
  */
  /*
  useEffect(() => {
    if (imageNotFound === true && intervalSet === true) {
      setImageIndex((prev) => prev + 1);
    }
  }, [imageNotFound]);
*/
  const handleImageError = async () => {
    setImageNotFound(true);

    setCount((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (imageNotFound === true) {
      setIntervalSet(true);
    }
  }, [imageNotFound]);

  const handleImageLoad = () => {
    setCount((prev) => prev + 1);
    setImageNotFound(false);
    setIntervalSet(false);
    setImageLoading(false);
  };

  useEffect(() => {
    if (intervalSet === true) {
      setIntervalId(setInterval(fetchNextImage, 5000));
      console.log("interval");
    }
    if (intervalSet === false && intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalSet]);
  useEffect(() => {
    if (intervalId !== null) {
      setImageIndex((prev) => prev + 1);
    }
  }, [intervalId]);

  useEffect(() => {
    setCount(0);
  }, [activeLocation]);

  return (
    <>
      <div className="  relative flex justify-center items-center h-[65vh] w-[100%] ">
        {JSON.stringify(imageHistory)}

        {imageData && imageData.hasOwnProperty("s3_path") && (
          <TransformWrapper>
            <TransformComponent>
              <img
                className={
                  imageNotFound
                    ? "invisible absolute"
                    : "h-full rounded-sm w-[96%] relative  shadow-xl"
                }
                src={imageData["s3_path"]}
                onError={
                  intervalSet === false
                    ? handleImageError
                    : setImageNotFound(true)
                }
                onLoad={handleImageLoad}
              />
            </TransformComponent>
          </TransformWrapper>
        )}
        {imageNotFound && (
          <DataNotFound message={"Image not found"}></DataNotFound>
        )}
      </div>
      <div className="flex-1 relative w-full h-full flex rounded-xl  gap-3 justify-center items-center  ">
        <ul className="my-2  w-full relative  bg-opacity-30 rounded-lg flex py-4 px-16   justify-center items-center gap-4 ">
          {judgeButtons.map((button) => {
            return (
              <li>
                <SecondaryButton
                  onClick={button.action}
                  width={"25%"}
                  name={button.name}
                  disabled={button.disabled}
                  icon={button.icon}
                ></SecondaryButton>
                <div
                  style={{
                    marginTop: "5.5px",
                    display: "flex",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    {judgeButtonState.hasOwnProperty(
                      button.name.toLowerCase()
                    ) ? (
                      <>
                        <span
                          style={{
                            marginRight: "8px",
                          }}
                        >
                          {`${judgeButtonState[button.name.toLowerCase()]}/${
                            judgeButtonState["totalGuessed"]
                          }`}
                        </span>
                        {judgeButtonState[button.name.toLowerCase()] > 0 ? (
                          <span
                            style={{
                              border: ".5px solid black",
                              borderRadius: "2px",
                              padding: ".75px 2px",
                            }}
                          >
                            {(
                              (judgeButtonState[button.name.toLowerCase()] /
                                judgeButtonState["totalGuessed"]) *
                              100
                            ).toFixed(2)}
                            %
                          </span>
                        ) : (
                          <span
                            style={{
                              border: ".5px solid black",
                              borderRadius: "2px",
                              padding: ".75px 2px",
                            }}
                          >
                            0%
                          </span>
                        )}
                        <span></span>
                      </>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          {imageLoading && (
            <li className="absolute left-[90%] right-0 top-0 right-0 m-auto">
              <FadeLoader
                speedMultiplier={2}
                color={"rgb(153 27 27)"}
              ></FadeLoader>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default HomeContent;
