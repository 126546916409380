import { useAuth0 } from "@auth0/auth0-react";
import Header from "./Header";
import Content from "./Content";
import PrimaryButton from "./PrimaryButton";
import HotKeys from "./HotKeys";
import SecondaryButton from "./SecondaryButton";
import { useState, useEffect, useRef } from "react";
import DataNotFound from "./DataNotFound";
import ImageData from "./ImageData";
import HomeContent from "./HomeContent";
import Select from "react-select";

import _ from "lodash";

import { ArrowBack, SkipNext } from "@mui/icons-material";

let baseAPI =
  "https://lk6e3mc867.execute-api.us-west-2.amazonaws.com/beta/lpr-low-confidence";

function Home() {
  const [activeLocation, setActiveLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [imageData, setImageData] = useState({});
  const [imageNotFound, setImageNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [submitQuery, setSubmitQuery] = useState("");
  const [searchedResult, setSearchedResult] = useState("");
  //const [prevImageData, setPrevImageData] = useState({});
  const [imageLoading, setImageLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [searched, setSearch] = useState(false);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const usedImageStats = ["object_id", "confidence", "ts"];
  const [judgeButtonState, setJudgeButtonState] = useState({
    illegible: 0,
    occluded: 0,
    skip: 0,
    totalGuessed: 0,
  });
  const [imageHistory, setImageHistory] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const mounted = useRef(null);
  const submitInputRef = useRef(null);
  const searchInputRef = useRef(null);
  const lastPressed = useRef(null);
  const controller = new AbortController();
  const signal = controller.signal;
  const THROTTLE_THRESHOLD = 700;

  const fetchNextImage = async (backgroundLoading = false) => {
    try {
      /*
      console.log(imageIndex, imageHistory.length);
      if (imageIndex + 1 < imageHistory.length) {
        console.log("hiii");
        console.log(imageHistory);
        setImageData(imageHistory[imageIndex + 1]);
        return;
      }
      */
      if (
        backgroundImages.length > 0 &&
        backgroundLoading !== true &&
        imageNotFound === false
      ) {
        setImageLoading(true);
        setImageHistory([...imageHistory, backgroundImages.pop()]);
        return;
      }

      const currLocation = activeLocation;
      if (backgroundLoading !== true) {
        setImageLoading(true);
      }
      await fetch(`${baseAPI}?location_code=${activeLocation}`, {
        signal: signal,
        headers: new Headers(await authRequest()),
      })
        .then((res) => res.json())
        .then((data) => {
          //check if location was changed while request is being made
          if (activeLocation && activeLocation === currLocation) {
            if (backgroundLoading === true) {
              setBackgroundImages([...backgroundImages, data]);
              return;
            }
            console.log(data, backgroundLoading);
            setImageHistory([...imageHistory, data]);
            setImageLoading(false);

            // updateAfterImageFetch(data);
          }
        });
    } catch (error) {
      console.log(error.name);
      if (error.name === "AbortError") {
      }
      console.log(error);
    }
  };

  const submit = async () => {
    try {
      if (submitQuery !== "") {
        await fetch(
          `${baseAPI}?lpr_id=${imageData["id"]}&resolution=SUBMITTED&value=${submitQuery}`,
          {
            method: "POST",
            headers: new Headers(await authRequest()),
          }
        );
        await fetchNextImage();
        setImageIndex((prev) => prev + 1);
        setSubmitQuery("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const search = async () => {
    try {
      if (searchQuery !== "") {
        fetch(`${baseAPI}?lpr_id=${imageData["id"]}&object_id=${searchQuery}`, {
          headers: new Headers(await authRequest()),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.hasOwnProperty("s3_path")) {
              if (data && Object.keys(data.length > 1)) {
                setImageData(data);
                fetchNextImage(true);
              }
            }
          });
        setSearchQuery("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (backgroundImages.length < 30) {
      fetchNextImage(true);
    }
  }, [backgroundImages]);
  useEffect(() => {
    console.log(imageHistory);
    if (imageHistory.length === 0) {
      setImageIndex(0);
      fetchNextImage();
    }

    if (imageHistory.length > 0) {
      setImageData(imageHistory[imageIndex]);
    }
  }, [imageHistory]);

  useEffect(() => {
    if (!_.isEmpty(imageData)) {
      if (
        imageData.hasOwnProperty("original_value") &&
        imageData["original_value"] &&
        imageData !== ""
      ) {
        setSubmitQuery(imageData["original_value"]);
      } else {
        setSubmitQuery("null");
      }
    }
  }, [imageData]);
  const handleSimpleButtonClick = async (action, event) => {
    try {
      console.log("hi");
      console.log(action, event);
      const copyJudgeButtonState = Object.assign(judgeButtonState);
      if (
        copyJudgeButtonState.hasOwnProperty(action.toLowerCase()) &&
        copyJudgeButtonState.hasOwnProperty("totalGuessed")
      ) {
        copyJudgeButtonState[action.toLowerCase()] += 1;
        copyJudgeButtonState["totalGuessed"] += 1;
        setJudgeButtonState(copyJudgeButtonState);
      }

      console.log(Date.now(), lastPressed.current);
      if (
        lastPressed.current !== null &&
        Math.abs(Date.now() - lastPressed.current) <= THROTTLE_THRESHOLD
      ) {
        return;
      }
      if (imageIndex + 1 <= imageHistory.length) {
        if (event !== undefined) {
          event.currentTarget.disabled = true;
          setButtonDisabled(true);
        }
        await fetch(
          `${baseAPI}?lpr_id=${imageData["id"]}&resolution=${action}`,
          {
            method: "POST",
            headers: new Headers(await authRequest()),
          }
        );
        await fetchNextImage();
        setImageIndex((prev) => prev + 1);
        lastPressed.current = Date.now();

        setButtonDisabled(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToPreviousImage = () => {
    const resImageHistory = [...imageHistory];
    resImageHistory.pop();
    setImageHistory(resImageHistory);
    setImageIndex((prev) => prev - 1);
    lastPressed.current = Date.now();
  };
  const judgeButtons = [
    {
      name: "Back",
      action: (e) => goToPreviousImage(e),
      disabled:
        imageLoading === true ||
        imageNotFound === true ||
        imageIndex === 0 ||
        buttonDisabled === true,
      icon: <ArrowBack></ArrowBack>,
    },
    {
      name: "Illegible",
      action: (e) => handleSimpleButtonClick("ILLEGIBLE", e),
      icon: null,
      disabled:
        imageLoading === true ||
        imageNotFound === true ||
        buttonDisabled === true,
    },
    {
      name: "Occluded",
      action: (e) => handleSimpleButtonClick("OCCLUDED", e),
      icon: null,
      disabled:
        imageLoading === true ||
        imageNotFound === true ||
        buttonDisabled === true,
    },

    {
      name: "Skip",
      action: (e) => handleSimpleButtonClick("SKIP", e),
      icon: SkipNext,
      disabled:
        imageLoading === true ||
        imageNotFound === true ||
        (imageData && Object.keys(imageData).length === 1),
    },
  ];
  console.log(judgeButtons, lastPressed.current, Date.now());

  const hotKeys = [
    { name: "Edit plate #", key: "e", index: 0 },
    //{ name: "Lookup", key: "l" },
    { name: "Occluded", key: "o", index: 0 },
    { name: "Back", key: "b", index: 0 },
    { name: "Submit", key: "s", index: 0 },
    { name: "Illegible", key: "i", index: 0 },
    { name: "Skip", key: "k", index: 1 },
  ];

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (activeLocation === "" && locations.length > 0) {
      setActiveLocation(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (activeLocation !== "") {
      console.log(intervalId);
      setImageHistory([]);
    }
  }, [activeLocation]);

  const cancelRequestAndSetActiveLocation = (e) => {
    controller.abort();
    setBackgroundImages([]);
    setActiveLocation(e.value);
  };
  useEffect(() => {}, [imageIndex]);

  /*
  useEffect(() => {
    setImageData(temporaryImageData);
  }, [prevImageData]);
  useEffect(() => {}, [imageData]);
?*/
  const fetchLocationData = async () => {
    fetch(`${baseAPI}?location_code=${activeLocation}`, {
      headers: new Headers(await authRequest()),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => setImageHistory([...imageHistory, data]));
  };

  /*
  useEffect(() => {
    console.log(imageHistory, imageData);
    console.log(!_.some(imageHistory, imageData));
    if (!_.some(imageHistory, imageData) && !_.isEmpty(imageData)) {
      setImageHistory([...imageHistory, imageData]);
    }
    //when back button is clicked decrement image index
  }, [imageData]);
*/
  async function authRequest() {
    return {
      Authorization: `Bearer ${await getAccessTokenSilently({
        username: user.email,
      })}`,
    };
  }

  const fetchLocations = async () => {
    await fetch(baseAPI, {
      headers: new Headers(await authRequest()),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.hasOwnProperty("locations")) {
          setLocations(data["locations"]);
          setActiveLocation(data["locations"][0]);
        }
      });
  };

  useEffect(() => {
    if (activeLocation !== "" && backgroundImages.length === 0) {
      fetchNextImage(true);
    }
  }, [activeLocation]);

  const handleKeyDown = (event) => {
    //dont handle shortcuts if user is actively submitting or searching license plate, handle enter key
    if (
      ((imageLoading === true ||
        imageNotFound === true ||
        submitInputRef.current === document.activeElement ||
        searchInputRef.current === document.activeElement) &&
        event.key !== "Enter") ||
      (lastPressed.current !== null &&
        Math.abs(Date.now() - lastPressed.current) < THROTTLE_THRESHOLD) ||
      event.repeat === true
    ) {
      return;
    }

    console.log(
      Date.now(),
      lastPressed.current,
      Date.now() - lastPressed.current
    );
    switch (event.key) {
      case "s":
        if (submitQuery !== "") {
          submit();
        }
        break;
      case "i":
        handleSimpleButtonClick("ILLEGIBLE");
        break;
      case "o":
        handleSimpleButtonClick("OCCLUDED");
        break;
      case "b":
        if (imageIndex > 0) goToPreviousImage();
        break;
      case "e":
        submitInputRef.current.focus();
        break;
      case "k":
        handleSimpleButtonClick("SKIP");
        break;
      /*
        case "l":
          searchInputRef.current.focus();
          break;
          */
      case "Enter":
        if (submitInputRef.current === document.activeElement) {
          submit();
        } else if (searchInputRef.current === document.activeElement) {
          search();
        }
        break;
      default:
        return;
    }
    lastPressed.current = Date.now();
    event.preventDefault();
  };

  useEffect(() => {
    fetchLocations();
    mounted.current = true;
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  /*
  const handleKeyDown = (event) => {
    if (!event.repeat) {
      switch (event.key) {
        case "i":
          console.log(imageData);
          break;
      }
      console.log(event);
      console.log(imageData);
    }
  };
  */

  /*
  useEffect(() => {
    setImageIndex(
      imageHistory.findIndex((image) => _.isEqual(image, imageData))
    );
  }, [imageHistory]);
  */
  return (
    <div className="h-full w-full flex justify-center items-center  bg-gradient-to-t from-gray-100  via-gray-100  to-gray-200">
      <div className=" py-4  px-5 flex gap-[11px] items-center justify-center h-[97%] w-[96%]  rounded-md  h-full w-full bg-gray-100 rounded-md  bg-opacity-90 ">
        <div className="py-4 w-[18%]  h-[100%]  flex flex-col  justify-center items-center rounded-md bg-white bg-opacity-100 shadow-xl">
          <div className="min-h-[40%] min-w-full px-8">
            <ImageData
              imageData={imageData}
              usedImageStats={usedImageStats}
            ></ImageData>
          </div>
          <HotKeys hotKeys={hotKeys}></HotKeys>
        </div>
        <div className=" h-full flex-1 flex shadow-xl  flex-col  rounded-md bg-white border-1 border-gray-800 ">
          <div className="   min-h-[14%] flex  items-center justify-center gap-6   rounded-lg ">
            <SecondaryButton
              onClick={() => submit()}
              name={"Submit"}
              disabled={
                submitQuery === "" ||
                imageLoading === true ||
                buttonDisabled === true
              }
            ></SecondaryButton>
            <input
              ref={submitInputRef}
              value={submitQuery}
              onChange={(e) => setSubmitQuery(e.target.value.toUpperCase())}
              className=" shadow-md bg-white bg-opacity-90 p-1  rounded-md focus:outline-red-800"
            />

            <Select
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  padding: 10,
                  color: "rgb(153 27 27)",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "rgb(153 27 27)",
                    color: "white",
                  },
                }),
                menu: (provided, state) => ({
                  ...provided,

                  backgroundColor: "white",
                  overflowX: "hidden",
                }),
                control: () => ({
                  // none of react-select's styles are passed to <Control />

                  width: 300,
                  display: "flex",
                  borderRadius: "4px",
                  background: "rgba(255,255,255,.8)",
                  boxShadow:
                    "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                }),
              }}
              value={{ label: activeLocation }}
              options={locations.map((location) => {
                return {
                  value: location,
                  label: location,
                };
              })}
              isDisabled={imageLoading}
              onChange={(e) => cancelRequestAndSetActiveLocation(e)}
            ></Select>
            <div className=" flex w-fit gap-4 items-center ">
              <input
                ref={searchInputRef}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="bg-white outline-red-800  bg-opacity-90 p-1 rounded-md shadow-md"
              />
              <SecondaryButton
                onClick={() => search()}
                name={"Search"}
                disabled={
                  searchQuery === "" ||
                  imageLoading === true ||
                  buttonDisabled === true
                }
              ></SecondaryButton>
            </div>
          </div>
          <HomeContent
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
            activeLocation={activeLocation}
            locations={locations}
            setActiveLocation={setActiveLocation}
            judgeButtonState={judgeButtonState}
            judgeButtons={judgeButtons}
            imageData={imageData}
            imageNotFound={imageNotFound}
            setImageNotFound={setImageNotFound}
            imageLoading={imageLoading}
            setImageLoading={setImageLoading}
            fetchNextImage={fetchNextImage}
            intervalId={intervalId}
            setIntervalId={setIntervalId}
            setJudgeButtonState={setJudgeButtonState}
          ></HomeContent>
        </div>
      </div>
    </div>
  );
}

export default Home;
