import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { ClockLoader } from "react-spinners";
export const ProtectedRoute = ({ component, theme }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <ClockLoader
        cssOverride={{
          position: "absolute",
          left: "50%",
          top: "40%",
        }}
        color={"#742a2a"}
      ></ClockLoader>
    ),
  });

  return <Component theme={theme} />;
};
