function HotKeys({ hotKeys }) {
  return (
    //border-x-[.5px] border-[#262626] bg-red-800 rounded-lg px-2 py-1 text-white
    <div className="     text-[12px]">
      {
        <ul className="w-full divide-y  divide-red-800 divide-dashed  ">
          <li className="font-bold py-1 text-lg  ">
            <p className="text-center bg-opacity-90 font-bold text-xl shadow-2xl relative z-10 ">
              Shortcut Keys
            </p>
          </li>
          {hotKeys.map((key) => {
            return (
              <li className="pl-2 py-3 z-0 tracking-wide">
                <span className="ml-[.9px] inline-block text-xl font-light   ">
                  {key.name.substring(0, key.index)}
                </span>
                <span className="text-center  inline-block  bg-opacity-[90%] text-xl font-extrabold border-none  rounded-lg bg-red-800 text-white  z-10 shadow-lg w-[25px]">
                  {key.key}
                </span>
                <span className="ml-[.9px] inline-block text-xl font-light   ">
                  {key.name.substring(key.index + 1, key.name.length)}
                </span>
              </li>
            );
          })}
        </ul>
      }
    </div>
  );
}

export default HotKeys;
