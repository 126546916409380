function SecondaryButton({ name, onClick, disabled, icon }) {
  return (
    <button
      onClick={onClick}
      className=" shadow-md text-lg   text-white  bg-red-800 w-[120px] font-medium bg-opacity-95 ] text-red-900     px-3 py-[5px] rounded hover:bg-red-900 hover:text-white disabled:opacity-40 "
      disabled={disabled}
    >
      {icon !== null && <icon></icon>}
      {name}
    </button>
  );
}
export default SecondaryButton;
