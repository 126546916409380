import "./App.css";
import {useEffect,useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Home from "./Home";

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
     const login = async () => {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    }
    login()
  }, [isLoading]);
  return (
      <Routes>
        <Route
            exact
            path="/"
            element={<ProtectedRoute  component={Home} />}
        ></Route>
      </Routes>
  );
}

export default App;
